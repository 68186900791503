<template>
  <div>
    <el-card class="appointment-card">
      <div class="header">
        <span class="title">课程安排</span>
        <p class="nav-box">
          <span
            @click="handleNav(nav)"
            :class="teachingStatus === nav.teachingStatus ? 'active' : ''"
            v-for="(nav, i) in navArr"
            :key="i"
            >{{ nav.title }}</span
          >
        </p>
      </div>

      <div class="main">
        <h4>
          <span>授课时间</span>
          <span>课程状态</span>
          <span>课程名称</span>
          <span>课程人数</span>
          <span>地点场景</span>
          <span>学生名单</span>
        </h4>
        <div v-if="teacherAppointmentCourseList.length > 0">
          <div
            v-for="(item, index) in teacherAppointmentCourseList"
            :key="index"
            class="table"
          >
            <div :class="item.showStudentList ? 'row show' : 'row'">
              <p>{{ item.courseTime || item.courseLearningTime }}</p>
              <p>
                {{
                  item.teachingStatus == 2 ? "即将上课" : item.teachingStatus == 3? "已完结": "正在进行"
                }}
              </p>
              <p>{{ item.courseName }}</p>
              <p>{{ item.studentList.length }}</p>
              <p>{{ item.courseSite }}</p>
              <p @click="lookStudents(item)">
                {{ item.studentList.length > 0 ? "查看" : "暂无学生" }}
              </p>
            </div>
            <div
              :class="
                item.showStudentList ? 'studentList showList' : 'studentList'
              "
            >
              <el-table
                size="mini"
                :data="item.studentList"
                align="center"
              >
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column prop="studentName" label="姓名" width="80">
                </el-table-column>
                <el-table-column label="性别" width="50">
                  <template slot-scope="scope">
                    <span>{{ scope.row.sex == 0 ? "女" : "男" }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="gradeName" label="年级" width="80">
                </el-table-column>
                <el-table-column prop="schoolName" label="学校">
                </el-table-column>
                <el-table-column prop="userTele" label="联系电话" width="110">
                </el-table-column>
                <el-table-column
                  prop="attendanceStatusDes"
                  label="签到状态"
                  width="80"
                >
                </el-table-column>
              </el-table>
              <el-button
                @click="downLoadExcel(item)"
                style="margin-top: 10px"
                size="small"
                type="primary"
                >下载学生名单</el-button
              >
            </div>
          </div>
        </div>
        <empty v-else>暂无课程</empty>
      </div>
      <div class="pages">
        <el-pagination
          v-if="total > size"
          @current-change="toggleCurrent"
          :current-page="current"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="size"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getTeacherTeachRecordList_api } from "@/api/user.js";
import { sessionGet } from "@/utils/local.js";
import empty from "@/components/empty.vue";
export default {
  components: { empty },
  data() {
    return {
      teacherAppointmentCourseList: [],
      total: 0,
      size: 10,
      current: 1,
      teachingStatus: 2, // 2表示即将开课，3表示已完结，1表示正在进行
      navArr: [
        // 导航栏
        { title: "即将上课", teachingStatus: 2 },
        { title: "已完结", teachingStatus: 3 },
        { title: "正在进行", teachingStatus: 1 },
      ],
    };
  },
  methods: {
    // 获取教师的授课列表
    async getTeacherTeachRecordList() {
      let res = await getTeacherTeachRecordList_api({
        userId: sessionGet("userId"),
        pageSize: this.size,
        currentPage: this.current,
        teachingStatus: this.teachingStatus,
      });
      this.teacherAppointmentCourseList = res.resultList.map((item) => {
        item.showStudentList = false; // 添加是否显示学生列表
        return item;
      });
      this.total = res.total;
    },
    // 查看学生列表
    lookStudents(item) {
      if (item.studentList.length <= 0) {
        this.$message.error("该课程暂无学生");
      } else if (item.showStudentList) {
        this.teacherAppointmentCourseList.forEach((val) => {
          val.showStudentList = false; // 关闭所有
        });
      } else {
        this.teacherAppointmentCourseList.forEach((val) => {
          val.showStudentList = false; // 关闭所有
        });
        item.showStudentList = true;
      }
    },
    // 下载已预约学生名单
    downLoadExcel(item) {
      let stuData = JSON.parse(JSON.stringify(item.studentList));
      let data = [];
      stuData.forEach((item, index) => {
        data[index] = {
          studentName: item.studentName,
          sex: item.sex == 1 ? "男" : "女",
          gradeName: item.gradeName,
          schoolName: item.schoolName,
          userTele: item.userTele,
          attendanceStatusDes: item.attendanceStatusDes,
        };
      });
      // let fileName = item.courseName +' ('+ item.courseLearningTime+')';
      let fileName = item.courseName;
      //定义表头
      let str = `姓名,性别,年级,学校,联系电话,签到状态\n`;
      //增加\t为了不让表格显示科学计数法或者其他格式
      for (let i = 0; i < data.length; i++) {
        for (let item in data[i]) {
          str += `${data[i][item] + "\t"},`;
        }
        str += "\n";
      }
      //encodeURIComponent解决中文乱码
      let uri = "data:text/xls;charset=utf-8,\ufeff" + encodeURIComponent(str);
      //通过创建a标签实现
      let link = document.createElement("a");
      link.href = uri;
      //对下载的文件命名
      link.download = `${fileName || "表格数据"}.xls`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 切换预约状态导航
    handleNav(val) {
      this.current = 1;
      this.teachingStatus = val.teachingStatus;
      this.getTeacherTeachRecordList();
    },
    // 切换页码
    toggleCurrent(current) {
      this.current = current;
      this.getTeacherTeachRecordList();
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
  },
  created() {
    this.getTeacherTeachRecordList();
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
.appointment-card {
  margin-bottom: 30px;
  .header {
    display: flex;
    .title {
      margin-right: 20px;
      font-weight: 700;
    }
    .nav-box {
      span {
        padding: 4px 10px;
        margin-left: 10px;
        font-size: 14px;
        user-select: none;
        cursor: pointer;
        &.active {
          color: @title-color;
          border-bottom: 1px solid @title-color;
        }
      }
    }
  }
  // 教师-课程通知
  .main {
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid #f4f4f4;
    h4 {
      margin-top: 20px;
      background-color: #eee;
      line-height: 40px;
      display: flex;
      span {
        &:nth-child(2n-1) {
          flex: 3;
        }
        &:nth-child(2n) {
          flex: 1;
        }
        &:nth-child(1) {
          flex: 3;
        }
      }
    }
    .table {
      .row {
        display: flex;
        border-top: 1px solid #f4f4f4;
        border-bottom: 1px solid #f4f4f4;
        background-color: #f8f8f8;
        color: #777;
        &.show {
          background-color: #f3f3f3;
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 8vh;
          min-height: 30px;
          &:nth-child(2n-1) {
            flex: 3;
          }
          &:nth-child(2n) {
            flex: 1;
          }
          &:nth-child(1) {
            flex: 3;
          }
          &:last-child {
            cursor: pointer;
            user-select: none;
            color: @title-color;
          }
        }
      }
      .studentList {
        margin-bottom: 30px;
        display: none;
        &.showList {
          display: block;
        }
      }
    }
  }
}
.pages {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
